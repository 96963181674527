<template>
  <div class="container">
    <div class="text-center my-4">
      <h5>แสตมป์การ์ด</h5>
    </div>
    <template v-if="dataList.length > 0">
      <div
        class="card shadow-sm mb-3 border-none rounded-xl"
        v-for="card of dataList"
        :key="card.id"
      >
        <b-row class="line-top">
          <b-col cols="4" class="p-0">
            <div>
              <!-- <b-img-lazy
                  v-if=""
                  v-bind="mainProps"
                  :src=""
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy> -->
              <!-- v-else -->
              <b-img-lazy
                :src="require('@/assets/images/mock-image-stamp.png')"
                class="lazy-image"
                alt="Image 1"
              ></b-img-lazy>
            </div>
          </b-col>
          <b-col>
            <div class="title line-clamp-2">{{ card.name }}</div>
            <div class="description line-clamp-2">description</div>
          </b-col>
        </b-row>

        <b-row class="px-2">
          <b-col cols="7" class="py-2">
            <div class="description">
              วันหมดอายุ: {{ card.validTo | momentFormat }}
            </div>
          </b-col>
          <b-col cols="5" class="p-0 text-right">
            <b-button
              class="btn-redeem"
              @click.prevent="$router.push(`stampcard/detail/${card.id}`)"
            >
              <div class="insert-redeem w-100">ดูรายละเอียด</div>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <template v-else>
      <div class="card shadow-sm mb-3 border-none rounded-xl p-5">
        <div class="text-center f-xl">ไม่มีแสตมป์การ์ด</div>
      </div>
    </template>
  </div>
</template>

<script>
const moment = require("moment");
export default {
  data() {
    return {
      dataList: []
    };
  },
  created() {
    this.getStampCard();
  },
  filters: {
    momentFormat(val) {
      return moment(val).format("DD MMM YYYY HH:mm");
    }
  },
  methods: {
    async getStampCard() {
      const res = await this.$axios(
        `${process.env.VUE_APP_API}/api/v1/StampCard`
      );
      this.dataList = res.detail;
    }
  }
};
</script>
<style lang="scss" scoped>
.total-point {
  border-bottom: 1px solid gray;
}
.totalpoint-font {
  font-weight: 600;
  font-size: var(--text-xl);
}
.lazy-image {
  font-size: 1px;
  -o-object-fit: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.content-reward-group {
  border-radius: 5px;
  background-color: #f0f0f0;
}
.title {
  font-size: var(--text-lg);
  font-weight: 600;
}

.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.until-date {
  font-size: var(--text-md);
}
.status-false {
  opacity: 0.6;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.line-top {
  border-bottom: 1px dashed #aaaaaa;
  margin: 0 0;
  // padding: 5px 0;
  // border-bottom: 1px dashed rgb(170, 170, 170);
}
.point {
  font-size: var(--text-md);
  font-weight: 600;
  // color: green;
}

.point-icon-color {
  color: #fff;
  // padding-left: 4px;
  padding: 0px 2px;
}
.icon-background {
  background-color: var(--reward-icon-color);
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 50px;
}

.out-of-stock {
  color: red;
  font-size: var(--text-md);
  font-weight: 600;
}
</style>
